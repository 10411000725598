import React, { Component } from "react";
import config from '../../../config';

class ReCaptchaValidation extends Component {
  static defaultProps = {
    handleChange: null
  };

  state = {
    Recaptcha: null
  };

  componentDidMount() {
    this.importRecaptcha();
  }

  importRecaptcha = async () => {
    window.recaptchaOptions = {
      useRecaptchaNet: true,
      removeOnUnmount: true
    };

    const {
      default: Recaptcha
    } = await import('react-google-recaptcha');

    this.setState({ Recaptcha });
  };

  componentWillUnmount() {
    delete window.recaptchaOptions;
    delete window.grecaptcha;
  }

  render () {
    const { handleChange } = this.props;
    const { Recaptcha } = this.state;

    return (
      <div>
        {Recaptcha && (
          <Recaptcha
            sitekey={ config.captchaSiteKey }
            onChange={ handleChange }
          />
        )}
      </div>
    );
  }
};

export default ReCaptchaValidation;