import React, { Component } from 'react';
import { Panel, Button } from "react-bootstrap";
import Dropdowns from './dropdowns';
import DatePicker from 'react-datepicker';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { Column, Row } from 'simple-flexbox';

import Select from "../Select";
import DashBoardRevenueComponent from "./dashBoardPanelComponents/DashBoardRevenueComponent";
import DashBoardPurchasesComponent from "./dashBoardPanelComponents/DashBoardPurchasesComponent";
import DashBoardPayoutComponent from "./dashBoardPanelComponents/DashBoardPayoutComponent";
import DashBoardPurchaseCountriesComponent from "./dashBoardPanelComponents/DashBoardPurchaseCountriesComponent";
import DashBoardCustomerBalancesComponent from "./dashBoardPanelComponents/DashBoardCustomerBalancesComponent";
import LastUpdateComponent from "./dashBoardPanelComponents/LastUpdateComponent";
import DashBoardPayoutCountriesComponent from "./dashBoardPanelComponents/DashBoardPayoutCountriesComponent";
import DashBoardDepositsProcessingFeeComponent from "./dashBoardPanelComponents/DashBoardDepositsProcessingFeeComponent";
import DashBoardPayoutsProcessingFeeComponent from "./dashBoardPanelComponents/DashBoardPayoutsProcessingFeeComponent";
import DashBoardDepositsPerMidComponent from "./dashBoardPanelComponents/DashBoardDepositsPerMidComponent";
import DashBoardPayoutsPerMidComponent from "./dashBoardPanelComponents/DashBoardPayoutsPerMidComponent";
import DashBoardDisplayDeals from './dashBoardPanelComponents/DashBoardDisplayDeals';

import { DASHBOARD_SELLS_EVENT, DASHBOARD_PURCHASES_EVENT, DASHBOARD_FTD_EVENT,
  DASHBOARD_REVENUE_EVENT, DASHBOARD_USERS_EVENT,
  DASHBOARD_PURCHASE_COUNTRIES_EVENT, DASHBOARD_CUSTOMER_BALANCES_EVENT,
  DASHBOARD_WITHDRAWALS_EVENT, DASHBOARD_PAYOUT_COUNTRIES_EVENT, DASHBOARD_PAYOUT_EVENT,
  DASHBOARD_PAYOUTS_PROCESSING_FEE, DASHBOARD_DEPOSITS_PROCESSING_FEE, DASHBOARD_DEPOSITS_PER_MIDS,
  DASHBOARD_PAYOUTS_PER_MIDS, DASHBOARD_DISPLAY_DEALS, DASHBOARD_AMOUNTS_PER_MID } from '../../../actions/types';

import Calendar from '../../../assets/images/calendar.png';
import "react-datepicker/dist/react-datepicker.css";
import '../../../assets/css/trustbucks/apiData.scss';
import '../../../assets/css/pacificCapital/apiData.scss';
import '../../../assets/css/apiData.css';

import * as actions from '../../../actions';
import { crudActions } from "../../../services/crudActions";
import { backEndBaseUrl, whiteLabel } from "../../../config";
import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
const combineReducers =  require('../../../reducers/index');

class APIData extends Component {
  state = {
    controls: {
      timePeriod: {
        id: 0,
        value: 'today'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dropdowns: {
      periods: Dropdowns.periods,
      clients: Dropdowns.clients
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(0).from,
      toDateTimeStamp: this.getPeriod(0).to
    },
    client: {},
    timeZone: "",
    timeZones: [],
    defaultTimezone: "",
    timeZoneType: "",
    selectedPlatform: "all",
    lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),
    subscribed: false,
    periodId: 0  //today
  };

  subscribed = false;
  thisMonth = false;
  timeout = 250;
  reconnect = true;
  connectTimeout = null;
  heartbeatInterval = null;
  heartbeatMessage = "ping";

  componentDidMount() {
    const { dropdowns } = this.state;

    // manually adjust periods
    const updatedPeriods = dropdowns.periods.filter(p => p.id !== 7);
    updatedPeriods.splice(7, 0, { id: 7, value: 'last30Mins', label: 'Last 30 Minutes'});
    updatedPeriods.splice(8, 0, { id: 9, value: 'last15Mins', label: 'Last 15 Minutes'});

    this.setState({
      dropdowns: {
        ...dropdowns,
        periods: updatedPeriods
      }
    });

    return crudActions.get(`v1/clients/current`).then(
      clientData => {
        if (clientData) {
          this.setState({
            client: {
              id: clientData.id,
              value: clientData.name,
              label: clientData.name
            },
            timeZones: clientData.timeZones || [],
            defaultTimezone: clientData.defaultTimezone || [],
            timeZoneType: clientData.timeZoneType,
            timeZone: clientData.timeZones ? (clientData.timeZoneType === "TZ_V1" ? clientData.timeZones.find(tz => tz.value === clientData.defaultTimezone).value : clientData.timeZones[0].value) : ""
          }, () => {
            this.loadRestData();
            // this.connect();
          });
        }
      }
    ).catch(
      (e) => {
        this.props.showMessage(e.message);
      }
    );  
  };

  componentWillUnmount() {
    if (this.state.ws) {
      this.state.ws.close();
    }

    this.reconnect = false;
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.connectTimeout);
  };

  connect = () => {
    const { client } = this.state;
    const user = JSON.parse(localStorage.user);
    const ws = new WebSocket(
      (window.location.protocol === "https:" ? "wss" : "ws") + "://" + backEndBaseUrl.split("/")[2] + "/dashboard-ws", //?token=" + user.accessToken
      //possible solution for putting token in headers
      [user.accessToken]
    );
    const that = this;

    ws.onopen = () => {
      console.log("WebSocket connected"); // eslint-disable-line no-console

      this.setState({
        ws: ws
      });

      ws.send(
        JSON.stringify({
          clientIds: [client.id],
          "subscribed": this.subscribed
        })
      );

      const heartbeatMessage = this.heartbeatMessage;

      if (this.heartbeatInterval === null) {
        this.heartbeatInterval = setInterval(function() {
          ws.send(heartbeatMessage);
        }, 60000);
      }

      that.timeout = 250;
      clearTimeout(this.connectTimeout);
    };

    ws.onclose = e => {
      console.log( // eslint-disable-line no-console
        `Socket is closed. Reconnect will be attempted in ${(that.timeout * 2) / 1000} second.`,
        e
      );

      that.timeout = that.timeout * 2;
      this.connectTimeout = setTimeout(this.check, Math.min(10000, that.timeout));
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    };

    ws.onmessage = evt => {
      if (evt.data === "pong") {
        return;
      }
      const message = JSON.parse(evt.data);
      this.updateDashboard(message);
    };

    ws.onerror = err => {
      console.error( // eslint-disable-line no-console
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  check = () => {
    const { ws } = this.state;
    if ((!ws || ws.readyState === WebSocket.CLOSED) && this.reconnect) {
      // this.connect();
    }
  };

  updateDashboard = (data) => {
    this.setState({
      lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY')
    });

    switch(data.type) {
    case 'USERS':
      combineReducers.default.dispatch({type: DASHBOARD_USERS_EVENT, socket: true, payload: data.data});
      break;
    case 'REVENUE':
      combineReducers.default.dispatch({type: DASHBOARD_REVENUE_EVENT, socket: true, payload: data.data});
      break;
    case 'SELL_REQUESTS':
      combineReducers.default.dispatch({type: DASHBOARD_SELLS_EVENT, socket: true, payload: data.data});
      break;
    case 'DEPOSITS_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PER_MIDS, socket: true, payload: data.data});
      break;
    case 'PAYOUTS_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PER_MIDS, socket: true, payload: data.data});
      break;
    case 'PURCHASE_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_PURCHASE_COUNTRIES_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    case 'WITHDRAWALS_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_WITHDRAWALS_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    case 'FTD_STATS':
      combineReducers.default.dispatch({type: DASHBOARD_PURCHASES_EVENT, socket: true, payload: data.data.purchases});
      combineReducers.default.dispatch({type: DASHBOARD_FTD_EVENT, socket: true, payload: data.data.ftds});
      break;
    case 'CUSTOMER_BALANCES':
      combineReducers.default.dispatch({type: DASHBOARD_CUSTOMER_BALANCES_EVENT, socket: true, payload: data.data});
      break;
    case 'PAYOUT_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_COUNTRIES_EVENT, socket: true, payload: data.data});
      break;
    case 'PAYOUT_REQUESTS':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_EVENT, socket: true, payload: data.data});
      break;
    case 'DEPOSIT_PROCESSING_FEES':
      combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PROCESSING_FEE, socket: true, payload: data.data});
      break;
    case 'PAYOUT_PROCESSING_FEES':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PROCESSING_FEE, socket: true, payload: data.data});
      break;
    case 'DEPOSITS_VOLUME_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_AMOUNTS_PER_MID, socket: true, payload: data.data});
      break;
    case 'DISPLAY_DEALS':
      combineReducers.default.dispatch({type: DASHBOARD_DISPLAY_DEALS, socket: true, payload: data.data});
      break;
    default:
      console.log('Wrong type ' + data.type); // eslint-disable-line no-console
    }
  };

  loadRestData = () => {
    const { client, selectedPlatform, timeStamp, periodId, timeZone, timeZones, timeZoneType, controls } = this.state;

    const period = this.getPeriod(periodId);
    if (periodId !== 8) {
      //we do not override dates for custom date picker
      timeStamp.fromDateTimeStamp = period.from;
      timeStamp.toDateTimeStamp = period.to;
    }

    const dateFormat = "DD.MM.YYYY-HH:mm:ss";

    let timeZoneOffset = "00:00";
    if (timeZone && timeZones.length && timeZoneType !== "TZ_V2") {
      timeZoneOffset = timeZones.find(zone => zone.value === timeZone).offset;
    }

    let fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
    let toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
        
    if (periodId === 8) {
      fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
      toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
    }

    this.setState({
      lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY')
    });

    const requestParams = `fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&timeZone=${timeZone}&timeZoneType=${timeZoneType}&platform=${selectedPlatform}&timePeriod=${controls.timePeriod.value}`;

    crudActions.get(`v2/clients/dashboard/revenue?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_REVENUE_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/country/purchases?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PURCHASE_COUNTRIES_EVENT, socket: false, payload: data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, sse: false, payload: data.purchaseMethodsPie});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/payout?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/ftd?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PURCHASES_EVENT, socket: false, payload: data.purchases});
        combineReducers.default.dispatch({type: DASHBOARD_FTD_EVENT, socket: false, payload: data.ftds});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/customer/balances?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_CUSTOMER_BALANCES_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/country/payout?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_COUNTRIES_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/deposit-fees?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PROCESSING_FEE, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/deposits-per-mid?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PER_MIDS, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/payouts-per-mid?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PER_MIDS, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/payout-fees?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PROCESSING_FEE, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });
    crudActions.get(`v2/clients/dashboard/display-deals?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_DISPLAY_DEALS, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });
    crudActions.get(`v2/clients/dashboard//amounts-per-mid?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_AMOUNTS_PER_MID, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

  };

  onChangeFilter = (e) => {
    const { /*client, ws,*/ selectedPlatform } = this.state;
    e.preventDefault();
    this.subscribed = this.thisMonth && selectedPlatform === "all";
    // ws.send(
    //   JSON.stringify({
    //     clientIds: [client.id],
    //     "subscribed": this.subscribed
    //   })
    // );
    this.loadRestData();
  };

  getPeriod(periodType) {
    let timeZoneOffset = "00:00";
    if (this.state) {
      if (this.state.timeZone && this.state.timeZones && this.state.timeZoneType !== "TZ_V2") {
        timeZoneOffset = this.state.timeZones.find(zone => zone.value === this.state.timeZone).offset;
      }
    }

    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(23).minute(59).second(59),
        id: periodType
      };
    case 2: // this month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).hour(23).minute(59).second(59),
        id: periodType
      };
    case 3: // last month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1)
          .date(moment().utc().utcOffset(timeZoneOffset).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).daysInMonth()).hour(23).minute(59).second(59),
        id: periodType
      };
    case 4: // this year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).hour(23).minute(59).second(59),
        id: periodType
      };
    case 5: // last year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(11).date(31).hour(23).minute(59).second(59),
        id: periodType
      };
    case 6: // last hour
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'hour'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 7: // last 30 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(30, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 9: // last 15 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(15, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 0: // today
      return {
        from: moment().utc().utcOffset(timeZoneOffset).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    default: // custom period
      return {
        from: moment().utc().utcOffset(timeZoneOffset).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).hour(23).minute(59).second(59),
        id: periodType
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }
    //

    this.setState({
      controls: controls,
      timeStamp: timeStamp,
      periodId: value.id
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = {...this.state.controls};
    const timeStamp = {...this.state.timeStamp};

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({ controls: controls, timeStamp: timeStamp, isSubmitting: false });
  };

  isDateValid = (current, state) => {
    const { timeStamp, fromDate = timeStamp.fromDateTimeStamp, toDate = timeStamp.toDateTimeStamp, timeZone, timeZones, timeZoneType } = this.state;

    let timeZoneOffset = "00:00";
    if (timeZone && timeZones.length && timeZoneType !== "TZ_V2") {
      timeZoneOffset = timeZones.find(zone => zone.value === timeZone).offset;
    }

    const yesterday = Datetime.moment().utc().utcOffset(timeZoneOffset).subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  onValueChange = (event, fieldId) => {
    this.setState({
      [fieldId]: event.value
    }, () => {
      if (fieldId === "timeZone") {
        const currentPeriod = this.state.dropdowns.periods.find(elem => elem.value === this.state.controls.timePeriod.value);
        this.onSelectPeriodChange(currentPeriod, "timePeriod");
      }
    });
  };

  render() {
    const controls = { ...this.state.controls };
    const { lastUpdate, timeZone, timeZones, timeZoneType, dropdowns } = this.state;

    const dateFormat = "dd.MM.yyyy HH:mm:ss";
    const isDateFieldDisabled = controls.timePeriod.id !== 8;

    return (
      <Row flexGrow={ 1 } className={ `${whiteLabel} module apidata` } vertical='start'>
        <Column flexGrow={ 1 }>
          <LastUpdateComponent lastUpdate={ lastUpdate }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%'} }>
              <Panel>
                <Panel.Heading className={ whiteLabel }>
                  <Panel.Title> DASHBOARD SETTINGS </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ { overflow: 'unset' } }>
                    <form onSubmit={ (e) => this.onRunFilter(e) }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="filter-row">
                        <Column flexGrow={ 1 } className="input-column">
                          <label> Merchant </label>
                          <input
                            id="client"
                            name="client"
                            className="form-control"
                            value={ this.state.client.value || '' }
                            disabled
                          />
                        </Column>
                        {timeZoneType === "TZ_V1" ? (
                          <>
                            <Column flexGrow={ 1 } className="input-column">
                              <label> Time Zone </label>
                              <Select
                                id="timeZone"
                                name="timeZone"
                                value={ timeZone || '' }
                                required={ true }
                                clearable={ false }
                                onChange={ (value) => this.onValueChange(value, "timeZone") }
                                options={ timeZones }
                              />
                            </Column>
                            <Column flexGrow={ 1 } className="input-column empty-column">
                              <span/>
                            </Column>
                            <Column flexGrow={ 1 } className="input-column empty-column">
                              <span/>
                            </Column>
                          </>
                        ) : (
                          <>
                            <Column flexGrow={ 1 } className="input-column">
                              <label>Time Period</label>
                              <Select
                                id="timePeriod"
                                name="timePeriod"
                                value={ controls.timePeriod.value || '' }
                                required={ true }
                                clearable={ false }
                                enableSort={ false }
                                onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                                options={ dropdowns.periods }
                              />
                            </Column>
                            <Column flexGrow={ 1 } className="input-column first-datepicker">
                              <label>From Date</label>
                              <img src={ Calendar } className="calendar-svg" alt=""/>
                              <DatePicker
                                selected={ convertUTCToLocalDate(this.state.timeStamp.fromDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ dateFormat }
                                timeFormat="HH:mm:ss"
                                showTimeSelect={ true }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                                disabled={ isDateFieldDisabled }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                              />
                            </Column>
                            <Column flexGrow={ 1 } className="input-column">
                              <label>To Date</label>
                              <img src={ Calendar } className="calendar-svg" alt=""/>
                              <DatePicker
                                selected={ convertUTCToLocalDate(this.state.timeStamp.toDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ dateFormat }
                                timeFormat="HH:mm:ss"
                                showTimeSelect={ true }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                                disabled={ isDateFieldDisabled }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'to') }
                              />
                            </Column>
                          </>
                        )}
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="filter-row">
                        {timeZoneType === "TZ_V1" ? (
                          <>
                            <Column flexGrow={ 1 } className="input-column">
                              <label>Time Period</label>
                              <Select
                                id="timePeriod"
                                name="timePeriod"
                                value={ controls.timePeriod.value || '' }
                                required={ true }
                                clearable={ false }
                                enableSort={ false }
                                onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                                options={ dropdowns.periods }
                              />
                            </Column>
                            <Column flexGrow={ 1 } className="input-column first-datepicker">
                              <label>From Date</label>
                              <img src={ Calendar } className="calendar-svg" alt=""/>
                              <DatePicker
                                selected={ convertUTCToLocalDate(this.state.timeStamp.fromDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ dateFormat }
                                timeFormat="HH:mm:ss"
                                showTimeSelect={ true }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                                disabled={ isDateFieldDisabled }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                              />
                            </Column>
                            <Column flexGrow={ 1 } className="input-column">
                              <label>To Date</label>
                              <img src={ Calendar } className="calendar-svg" alt=""/>
                              <DatePicker
                                selected={ convertUTCToLocalDate(this.state.timeStamp.toDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ dateFormat }
                                timeFormat="HH:mm:ss"
                                showTimeSelect={ true }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                                disabled={ isDateFieldDisabled }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'to') }
                              />
                            </Column>
                          </>
                        ) : (
                          <>
                            {[0, 1, 2].map(item => 
                              <Column
                                key={ item }
                                flexGrow={ 1 }
                                vertical="start"
                                alignSelf="start"
                                className="input-column empty-column"
                              >
                                <span/>
                              </Column>
                            )}
                          </>
                        )}
                        <Column flexGrow={ 1 } className="input-column fullwidth-button">
                          <Button
                            type="submit"
                            className={ `${whiteLabel} btn defaultBtn` }
                            disabled={ this.state.isSubmitting }
                            onClick={ (e) => this.onChangeFilter(e) }
                          >
                            Filter
                          </Button>
                        </Column>
                      </Row>
                    </form>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>

            <Column className="table-column" flexGrow={ 1 } vertical='start'>
              <DashBoardRevenueComponent thisMonth={ this.thisMonth }/>
            </Column>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' >
                <DashBoardDepositsPerMidComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' >
                <DashBoardPayoutsPerMidComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardPurchaseCountriesComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPayoutCountriesComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPurchasesComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPayoutComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardDepositsProcessingFeeComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPayoutsProcessingFeeComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>
            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardCustomerBalancesComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <span/>
              </Column>
            </Row>
            <Column className="table-column" flexGrow={ 1 } vertical='start'>
              <DashBoardDisplayDeals thisMonth={ this.thisMonth }/>
            </Column>

          </Row>

        </Column>
      </Row>
    );
  }
}

export default connect(null, actions)(APIData);
